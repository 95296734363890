import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import SignsTemplates from '../views/SignsTemplates.vue'
// import NotFoundPage from '../views/NotFoundPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',    
    component: Home
  },  
  {
    path: '/signs',    
    component: SignsTemplates,
    redirect: '/signs/name',
    children: [
      {
        path: 'name',        
        component: () => import(/* webpackChunkName: "TemplateName" */ '../views/template/Name.vue')
      },
      {
        path: 'number',        
        component: () => import(/* webpackChunkName: "TemplateNumber" */ '../views/template/Number.vue')
      },
      {
        path: 'address',        
        component: () => import(/* webpackChunkName: "TemplateAddress" */ '../views/template/Address.vue')
      },
      {
        path: 'direction-enter',        
        component: () => import(/* webpackChunkName: "TemplateDirEntrance" */ '../views/template/DirEntrance.vue')
      },
      {
        path: 'direction-addr',        
        component: () => import(/* webpackChunkName: "TemplateDirAddr" */ '../views/template/DirAddress.vue')
      },
      {
        path: 'entrance',        
        component: () => import(/* webpackChunkName: "TemplateEntrance" */ '../views/template/Entrance.vue')
      },
    ],
  },
  {
    path: '/produce',
    name: 'Produce',        
    component: () => import(/* webpackChunkName: "produce" */ '../views/Produce.vue')
  }
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: '404',
  //   component: NotFoundPage
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
