<template lang="pug">
.content.panel
  router-view(v-slot="{ Component}")
    transition(name="fade" mode="out-in")
      component(:is="Component")
</template>

<script>
  export default {
    name: 'SignsTemplates'
  }
</script>

<style lang="scss">
.paper {
  background-color: $Paper
}
.panel {
  background-color: $Paper;
  padding: 20px 5px;  
  min-height: 0;

  @include respond-above(xs) {
    width: 70%;    
    padding: 60px 10px 20px;  
    min-height: 100vh;
  }

  @include respond-above(md) {
    width: 360px;    
    padding: 60px 10px 20px;  
    min-height: 100vh;    
  }
}

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;

}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter {
  opacity: .5;
}
.fade-leave-active {
  opacity: 0;
}
</style>